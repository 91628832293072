import * as PDFService from './pdf.service'
import i18n from '@/i18n'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { User } from '../auth/entity'

export const generateTransactionsResume = async (
  user: User
): Promise<{
  filename: string
  dataUrl: string
  dataBuffer: ArrayBuffer
  documentDefinition: TDocumentDefinitions
}> => {
  const pdfDocument = PDFService.create([
    {
      text: i18n.t('nsPDFTransactionsResume:title'),
      bold: true,
      style: 'header',
      alignment: 'center',
      background: '#fff',
    },
    PDFService.COMPONENTS.spacer(),

    // Basic Info
    {
      columns: [
        {
          stack: [
            PDFService.COMPONENTS.subHeader(i18n.t('nsPDFTransactionsResume:basicInfo.trazable')),
            {
              ul: [
                `${i18n.t('nsPDFTransactionsResume:basicInfo.businessName')}: ${i18n.t(
                  'nsPDFTransactionsResume:trazable.businessName'
                )}`,
                `${i18n.t('nsPDFTransactionsResume:basicInfo.nif')}: ${i18n.t(
                  'nsPDFTransactionsResume:trazable.nif'
                )}`,
                `${i18n.t('nsPDFTransactionsResume:basicInfo.address')}: ${i18n.t(
                  'nsPDFTransactionsResume:trazable.address'
                )}`,
              ],
            },
          ],
        },
        {
          stack: [
            PDFService.COMPONENTS.subHeader(i18n.t('nsPDFTransactionsResume:basicInfo.user')),
            {
              ul: [
                `${i18n.t('nsPDFTransactionsResume:basicInfo.name')}: ${user.name}`,
                `${i18n.t('nsPDFTransactionsResume:basicInfo.surnames')}: ${user.surnames}`,
                `${i18n.t('nsPDFTransactionsResume:basicInfo.phone')}: ${user.phone}`,
                `${i18n.t('nsPDFTransactionsResume:basicInfo.email')}: ${user.email}`,
              ],
            },
          ],
        },
      ],
    },
    PDFService.COMPONENTS.spacer(2),

    // Resume
    PDFService.COMPONENTS.subHeader(`${i18n.t('nsPDFTransactionsResume:transactions.title')}:`),
    {
      style: 'table',
      table: {
        widths: ['auto', 'auto', '*', '*'],
        headerRows: 1,
        dontBreakRows: true,
        body: [
          [
            {
              text: i18n.t('nsPDFTransactionsResume:transactions.date'),
              bold: true,
            },
            {
              text: i18n.t('nsPDFTransactionsResume:transactions.phase'),
              bold: true,
            },
            {
              text: i18n.t('nsPDFTransactionsResume:transactions.eurs'),
              bold: true,
            },
            {
              text: i18n.t('nsPDFTransactionsResume:transactions.tokens'),
              bold: true,
            },
          ],
          ...user.transactions.map(({ createdAt, phase, totalTokenEur, tokenAmount }) => [
            i18n.t('nsPDFTransactionsResume:transactions.dateValue', { date: new Date(createdAt) }),
            i18n.t(`nsPDFTransactionsResume:transactions.phases.${phase}`),
            i18n.t('nsPDFTransactionsResume:transactions.eursValue', { quantity: totalTokenEur }),
            i18n.t('nsPDFTransactionsResume:transactions.tokensValue', { quantity: tokenAmount }),
          ]),
        ],
      },
    },
  ])

  const pdfDataUrl = await PDFService.UTILS.pdfToDataUrl(pdfDocument)
  const pdfDataBuffer = await PDFService.UTILS.pdfToArrayBuffer(pdfDocument)

  return {
    filename: `${i18n.t('nsPDFTransactionsResume:filename', { date: new Date() })}`,
    dataUrl: pdfDataUrl,
    dataBuffer: pdfDataBuffer,
    documentDefinition: pdfDocument,
  }
}
