import { Config } from '@/config'
import { getTranslatedPath } from '@/i18n/utils'
import { PAGES } from '@/routes'
import axios from 'axios'
import { TokenInfo, WhitelistPrivateUser, WhitelistUser } from './entity'

export const getTokenInfo = async (): Promise<TokenInfo> => {
  const { data } = await axios.get<TokenInfo>(`${Config.API_URL}/tokens`)
  return data
}

/**
 *
 * @returns The amount in € of current sale progress
 */
export const getTokenSaleSaled = async (): Promise<string> => {
  const { data } = await axios.get<string>(`${Config.API_URL}/progressbar`)
  return data
}

export const getPaymentUrl = async (
  quantity: number
): Promise<{ uri: string; fields: Record<string, string> }> => {
  const { data } = await axios.get<{ uri: string; fields: Record<string, string> }>(
    `${Config.API_URL}/payments`,
    {
      params: new URLSearchParams({
        successUrl: `${window.location.origin}${getTranslatedPath(
          window.location.pathname,
          PAGES.PROFILE
        )}?paymentStatus=success&paymentMethod=fiat`,
        errorUrl: `${window.location.origin}${getTranslatedPath(
          window.location.pathname,
          PAGES.PROFILE
        )}?paymentStatus=error&paymentMethod=fiat`,
        errorRateLimitUrl: `${window.location.origin}${getTranslatedPath(
          window.location.pathname,
          PAGES.PROFILE
        )}?paymentStatus=rateLimit&paymentMethod=fiat`,
        quantity: quantity.toString(),
      }),
    }
  )
  return data
}

export const getPaymentCryptoUrl = async (quantity: number): Promise<string> => {
  const { data } = await axios.get<string>(`${Config.API_URL}/payments/crypto`, {
    params: new URLSearchParams({
      successUrl: `${window.location.origin}${getTranslatedPath(
        window.location.pathname,
        PAGES.PROFILE
      )}?paymentStatus=pending&paymentMethod=crypto`,
      errorUrl: `${window.location.origin}${getTranslatedPath(
        window.location.pathname,
        PAGES.PROFILE
      )}?paymentStatus=error&paymentMethod=crypto`,
      errorRateLimitUrl: `${window.location.origin}${getTranslatedPath(
        window.location.pathname,
        PAGES.PROFILE
      )}?paymentStatus=rateLimit&paymentMethod=crypto`,
      quantity: quantity.toString(),
    }),
  })
  return data
}

export const registryToWhitelistPrivate = async (user: WhitelistPrivateUser): Promise<void> => {
  await axios.post<void>(`${Config.API_URL}/private`, user)
}

export const registryToWhitelist = async (user: WhitelistUser): Promise<void> => {
  await axios.post<void>(`${Config.API_URL}/whitelist`, user)
}
