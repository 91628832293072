import { useCallback, useEffect, useState } from 'react'
import { TokenInfo } from './entity'
import { getTokenInfo } from './service'

interface UseTokenTRZInstance {
  data: (TokenInfo & { totalProfitSaleEur: string }) | null
  isLoading: boolean
  error: boolean
}

export const useTokenTRZ = (): UseTokenTRZInstance => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [tokenData, setTokenData] = useState<(TokenInfo & { totalProfitSaleEur: string }) | null>(
    null
  )

  const fetchTokenTRZData = useCallback(async () => {
    setHasError(false)
    setIsLoading(true)

    try {
      const responseTokenData = await getTokenInfo()
      // const responseTokenTotalProfitSaleEur = await getTokenSaleSaled()

      setTokenData({ ...responseTokenData, totalProfitSaleEur: '10000000' })
    } catch (error) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchTokenTRZData()
  }, [fetchTokenTRZData])

  return {
    data: tokenData,
    isLoading,
    error: hasError,
  }
}
