import { useCallback, useEffect, useState } from 'react'

export type Time = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export const useCountDown = (countDownDate: Date): Time => {
  const calculateRemainingTime = useCallback((distance: number): Time => {
    return {
      days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((distance % (1000 * 60)) / 1000),
    }
  }, [])

  const getDistance = useCallback(
    () => countDownDate.getTime() - new Date().getTime(),
    [countDownDate]
  )

  const [timer, setTimer] = useState<Time>(calculateRemainingTime(getDistance()))

  useEffect(() => {
    const interval = setInterval(function () {
      // Find the distance between now and the count down date
      const distance = getDistance()

      // Time calculations for days, hours, minutes and seconds
      const remainingTime = calculateRemainingTime(distance)
      setTimer({
        days: remainingTime.days > 0 ? remainingTime.days : 0,
        hours: remainingTime.hours > 0 ? remainingTime.hours : 0,
        minutes: remainingTime.minutes > 0 ? remainingTime.minutes : 0,
        seconds: remainingTime.seconds > 0 ? remainingTime.seconds : 0,
      })

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [calculateRemainingTime, countDownDate, getDistance])

  return timer
}
