import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {
  ChevronUpIcon,
  MenuIcon,
  UserCircleIcon,
  XIcon,
  TicketIcon,
  CogIcon,
  PencilAltIcon,
} from '@heroicons/react/outline'
import classNames from 'classnames'
import {
  Avatar,
  InputField,
  Modal,
  SIZE,
  Tooltip,
} from '@blockchain-traceability-sl/tailwind-components'
import { useAuth } from '@/features/auth/use-auth'
import { PAGES } from '@/routes'
import { navigate, PageProps } from 'gatsby'
import { isBrowser } from '@/utils'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/es'
import { useTokenTRZ } from '@/features/token/use-token'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { customErrorToNotify, useNotify } from '@/hooks/use-notify'
import { useSearchParams } from '@/hooks/use-search-params'
import { CountDownWrapper } from '@/components/CountDownWrapper'
import { Big } from 'big.js'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from '@/components/Link'
import { getTranslatedPath } from '@/i18n/utils'
import { UserUpdate } from '@/features/auth/entity'
import { isAddress } from '@ethersproject/address'
import { useSocket } from '@/features/socket/use-socket'
import { Helmet } from 'react-helmet'
import { customErrorToTrack } from '@/utils/error-to-analytics'
import { useLocation } from '@reach/router'
import { capitalize } from '@/utils/capitalize'
import { formatNumber } from '@/utils/format-number'
import { generateTransactionsResume } from '@/features/pdf/actions'
import { download } from '@/features/pdf/pdf.service'
import { useCookiesConfig } from '@/features/cookies/use-cookies'
import { CookieBanner } from '@/features/cookies/CookieBanner'
import { useToggle } from '@/hooks/use-toggle'
import { ButtonAction, ButtonMainLink } from '@/components/TokenLandingComponents'
import { VerificationCodeField } from '@/components/VerificationCodeField'
import { generateVerificationCode } from '@/features/auth'
import axios from 'axios'
import { TextButton } from '@/components/Typography'

// Images
import imgOG from '@/assets/images/og/ogimage.png'
import imgLogo from '@/assets/images/brand/Trazable_Logo_W.png'
import imgHeaderRectangle from '@/assets/images/rectangle-background.svg'
import imgCoinBackground from '@/assets/images/coin-background.png'
import imgKYCIssueDate from '@/assets/images/kyc-issue-date.jpeg'
import imgSquaresBackground from '@/assets/images/squares-background-horizontal.png'
import imgTimelineEndSaleES from '@/assets/images/profileSale/endsale_es.png'
import imgTimelineEndSaleEN from '@/assets/images/profileSale/endsale_en.png'
import imgUniswapLogo from '@/assets/images/tokenFarm/uniswap-uni-logo.png'
import { useConfig } from '@/config'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { InfuraProvider, JsonRpcProvider } from '@ethersproject/providers'

dayjs.extend(LocalizedFormat).locale('es')

const ProfilePage = ({
  pageContext,
}: PageProps<never, { language: string }>): JSX.Element | null => {
  const { t, i18n } = useTranslation('nsProfile')
  const config = useConfig()
  const auth = useAuth()
  const tokenData = useTokenTRZ()
  const notify = useNotify()
  const location = useLocation()
  const cookiesConfig = useCookiesConfig()

  useSocket()

  const [currentTab] = useState<number>(0)
  const [modalWalletShow, setModalWalletShow] = useState(false)
  const [modalKYCShow, toggleModalKYCShow] = useToggle(false)

  if (i18n.language !== pageContext.language) {
    i18n.changeLanguage(pageContext.language)
  }

  const asideNavigation = useMemo(
    () => [
      { name: t('aside.profile'), icon: UserCircleIcon },
      { name: t('aside.account'), icon: CogIcon },
    ],
    [t]
  )

  const handleUserVerify = useCallback(() => {
    window.analytics?.track('ACTION_CLICK_IDENTITY_VERIFICATION')
    auth?.verifyIdentity().catch(() => {
      window.analytics?.track('NAVIGATION_VIEW_IDENTITY_VERIFICATION_ERROR')
    })
  }, [auth])

  const formAccountUpdate = useFormik<UserUpdate & { verificationCodeGenerated: boolean }>({
    initialValues: {
      verificationCode: '',
      verificationCodeGenerated: false,
      publicAddress: auth?.user?.publicAddress,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      verificationCode: Yup.string().required(t('nsValidations:required')),
      publicAddress: Yup.string()
        .nullable()
        .required(t('nsValidations:required'))
        .test('isAddress', t('nsValidations:address'), value => {
          return (
            // Has value
            !!value &&
            // Is a string
            typeof value === 'string' &&
            // Is not empty
            value.trim() !== '' &&
            // Is a valid BSC address
            isAddress(value) &&
            // Starts with 0x (start without 0x is a valid address)
            value.startsWith('0x') &&
            // Is not our eth smart contract
            value !== '0x394A16744Dcd805BB0cA7252e70691f0dcAc56AA' &&
            // Is not our bsc smart contract
            value !== '0x52fe7b439753092f584917e3EFEa86A1cFD210f9'
          )
        })
        .test('isNotEthereumSmartContract', t('nsValidations:address'), async value => {
          if (!value) return false
          try {
            // Connect provider to ethereum mainnet
            const provider = new InfuraProvider('homestead', config.INFURA)
            // Get the address smart contract code
            const addressCode = await provider.getCode(value)
            // Expect to not has code because is not a smartcontract
            return addressCode === '0x'
          } catch {
            return false
          }
        })
        .test('isNotBSCSmartContract', t('nsValidations:address'), async value => {
          if (!value) return false
          try {
            // Connect provider to bsc mainnet
            const provider = new JsonRpcProvider('https://bsc-dataseed.binance.org/', {
              name: 'binance',
              chainId: 56,
            })
            // Get the address smart contract code
            const addressCode = await provider.getCode(value)
            // Expect to not has code because is not a smartcontract
            return addressCode === '0x'
          } catch {
            return false
          }
        }),
    }),
    onSubmit: ({ verificationCodeGenerated, ...data }) => {
      formAccountUpdate.setSubmitting(true)
      auth
        ?.update(data)
        .then(() => {
          notify.success({
            title: t('nsNotifications:success.updateAccount.title'),
            description: t('nsNotifications:success.updateAccount.description'),
          })
          window.analytics?.track('CUSTOMER_ADD_WALLET')
        })
        .catch(error => {
          if (axios.isAxiosError(error) && error.response?.status === 401) {
            auth?.signout()
          } else {
            notify.error(customErrorToNotify(error.response?.data))

            if (error.response?.data?.code === 15) {
              // Invalid verification code
              window.analytics?.track(
                'CUSTOMER_ADD_WALLET_ERROR',
                customErrorToTrack(
                  {
                    ...error.response?.data,
                    message: `Wrong code ${formAccountUpdate.values.verificationCode}`,
                  },
                  error.response?.status
                )
              )
            } else if (error.response?.data?.code === 16) {
              // Expired verification code
              window.analytics?.track(
                'CUSTOMER_ADD_WALLET_ERROR',
                customErrorToTrack(
                  {
                    ...error.response?.data,
                    message: `Expired code ${formAccountUpdate.values.verificationCode}`,
                  },
                  error.response?.status
                )
              )
            } else {
              window.analytics?.track(
                'CUSTOMER_ADD_WALLET_ERROR',
                customErrorToTrack(error.response?.data, error.response?.status)
              )
            }
          }
        })
        .finally(() => {
          setModalWalletShow(false)
          formAccountUpdate.setSubmitting(false)
          formAccountUpdate.resetForm()
        })
    },
  })

  const quaryParams = useSearchParams<{
    paymentStatus: 'error' | 'success'
    paymentMethod: 'fiat' | 'crypto'
    idenfyStatus: 'error' | 'success'
  }>()

  // Handle query params
  useEffect(() => {
    setTimeout(() => {
      if (quaryParams.paymentStatus) {
        if (quaryParams.paymentStatus === 'success') {
          auth?.removePaymentDelay()
          notify.success({
            title: t('nsNotifications:success.payment.title'),
            description: t('nsNotifications:success.payment.description'),
          })
          window.analytics?.track('CUSTOMER_BUY_TOKENS')
        } else if (quaryParams.paymentStatus === 'error') {
          auth?.removePaymentDelay()
          notify.error({
            title: t('nsNotifications:error.payment.title'),
            description: t('nsNotifications:error.payment.description'),
            actionText: t('nsNotifications:error.payment.action'),
          })
          window.analytics?.track('CUSTOMER_BUY_TOKENS_ERROR', {
            'Error message': `Cancel ${quaryParams.paymentMethod}`,
          })
        } else if (quaryParams.paymentStatus === 'pending') {
          notify.success({
            title: t('nsNotifications:success.paymentPending.title'),
            description: t('nsNotifications:success.paymentPending.description'),
          })
        }
      } else if (quaryParams.idenfyStatus) {
        if (quaryParams.idenfyStatus === 'success') {
          notify.success({
            title: t('nsNotifications:success.idenfy.title'),
            description: t('nsNotifications:success.idenfy.description'),
          })
          window.analytics?.track('CUSTOMER_VERIFY_IDENTITY')
        } else {
          notify.warn({
            title: t('nsNotifications:error.idenfyWarn.title'),
            description: t('nsNotifications:error.idenfyWarn.description'),
            actionText: t('nsNotifications:error.idenfyWarn.action'),
          })
          window.analytics?.track('CUSTOMER_VERIFY_IDENTITY_ERROR')
        }
      }

      navigate(window.location.pathname)
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quaryParams.paymentStatus, quaryParams.idenfyStatus, JSON.stringify(notify)])

  const handleClickBuyTokens = useCallback((exchange: 'Bittrex' | 'PancakeSwap' | 'Uniswap') => {
    window.analytics?.track('ACTION_CLICK_EXCHANGE', { Name: exchange })
  }, [])

  const handleCloseModalWallet = useCallback(() => {
    setModalWalletShow(false)
    setTimeout(formAccountUpdate.resetForm, 500)
  }, [formAccountUpdate])

  const handleGenerateVerificationCode = useCallback(() => {
    formAccountUpdate.setFieldValue('verificationCodeGenerated', true)
    generateVerificationCode()
      .then(() => {
        notify.success({
          title: t('nsNotifications:success.verificationCode.title'),
          description: t('nsNotifications:success.verificationCode.description'),
        })
      })
      .catch(error => {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          auth?.signout()
        } else {
          formAccountUpdate.setFieldValue('verificationCodeGenerated', false)
        }
      })
  }, [formAccountUpdate, notify, t, auth])

  const handleAddWallet = useCallback(() => {
    window.analytics?.track('ACTION_CLICK_ADD_WALLET')
    setModalWalletShow(true)
  }, [])

  // Phases timeline
  const salePhases = useMemo(() => {
    return i18n.language.startsWith('es') ? imgTimelineEndSaleES : imgTimelineEndSaleEN
  }, [i18n.language])

  // Analytics
  const navigationViewEventTracked = useRef({ tracked: false, Step: '' })
  useEffect(() => {
    if (
      isBrowser() &&
      auth?.user &&
      window.analytics &&
      navigationViewEventTracked.current.Step !==
        (!auth.user.verified
          ? 'KYC'
          : auth.user.transactions.length === 0
          ? 'Purchase'
          : !auth.user.publicAddress
          ? 'Wallet'
          : 'Completed')
    ) {
      window.analytics.identify(auth.user._id, {
        name: auth.user.name,
        email: auth.user.email,
      })
      window.analytics?.track('NAVIGATION_VIEW_PROFILE', {
        Step: !auth.user.verified
          ? 'KYC'
          : auth.user.transactions.length === 0
          ? 'Purchase'
          : !auth.user.publicAddress
          ? 'Wallet'
          : 'Completed',
      })
      navigationViewEventTracked.current.Step = !auth.user.verified
        ? 'KYC'
        : auth.user.transactions.length === 0
        ? 'Purchase'
        : !auth.user.publicAddress
        ? 'Wallet'
        : 'Completed'
    }
  }, [
    auth?.user,
    auth?.user?.publicAddress,
    auth?.user?.transactions.length,
    auth?.user?.verified,
    location,
  ])

  const handleGenerateResume = useCallback(async () => {
    if (auth?.user) {
      const transactionsResume = await generateTransactionsResume(auth.user)
      download(transactionsResume.documentDefinition, transactionsResume.filename)
    }
  }, [auth?.user])

  if (!auth || !auth.isAuthenticated || !auth.user) {
    if (isBrowser() && auth && (!auth.isAuthenticating || !auth.token)) {
      navigate(getTranslatedPath(window.location.pathname, PAGES.TOKEN))
    }
    return (
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name='description' content={t('meta.description')} />
        <meta name='robots' content='noindex, nofollow' />
        <meta name='facebook-domain-verification' content='gdt37htl4djqwmarnkdyct76lx1zos' />

        <link rel='canonical' href={`https://token.trazable.io${PAGES.PROFILE}`} />

        {/* Og Meta */}
        <meta property='og:locale' content='es_ES' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={t('meta.title')} />
        <meta property='og:description' content={t('meta.description')} />
        <meta property='og:url' content={`https://token.trazable.io${PAGES.PROFILE}`} />
        <meta property='og:site_name' content='Trazable' />
        <meta property='article:publisher' content='https://www.facebook.com/trazable/' />
        <meta property='article:modified_time' content='2021-04-09T11:41:00+00:00' />
        <meta property='og:image' content={imgOG} />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        {/* End OG Meta */}

        {/* Twitter Meta */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@iotrazable' />
        <meta name='twitter:label1' content='Tiempo de lectura' />
        <meta name='twitter:data1' content='3 minutos' />
        {/* End Twitter Meta */}

        <script type='application/ld+json' className='yoast-schema-graph'>{`
        {"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://token.trazable.io/#website","url":"https://token.trazable.io/","name":"Trazable","description":"Trazabilidad end-to-end de alimentos","potentialAction":[{"@type":"SearchAction","target":"https://token.trazable.io/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"es"},{"@type":"ImageObject","@id":"https://token.trazable.io/#primaryimage","inLanguage":"es","url":"https://token.trazable.io/assets/images/icono-trazable-150.png","width":150,"height":150,"caption":"trazable logo150 farm to fork blockchain traceability"},{"@type":"WebPage","@id":"https://token.trazable.io/#webpage","url":"https://token.trazable.io/","name":"Trazable - Trazabilidad end-to-end de alimentos","isPartOf":{"@id":"https://token.trazable.io/#website"},"primaryImageOfPage":{"@id":"https://token.trazable.io/#primaryimage"},"datePublished":"2019-01-22T08:16:34+00:00","dateModified":"2021-04-09T11:41:00+00:00","description":"Optimiza tu cadena de suministro con informaci\u00f3n a tiempo real de toda la cadena, gracias a nuestra plataforma blockchain","inLanguage":"es","potentialAction":[{"@type":"ReadAction","target":["https://token.trazable.io/"]}]}]}
      `}</script>

        {/* Google Tag Manager */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && (
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T432GJH');
        `}</script>
        )}
        {/* End Google Tag Manager */}

        {/* Segment analytics */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && (
          <script>{`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=window.GATSBY_APP_SEGMENT_WRITEKEY;;analytics.SNIPPET_VERSION="4.15.3";
          setTimeout(() => analytics.load(window.GATSBY_APP_SEGMENT_WRITEKEY), 500);
          analytics.page();
          }}();
        `}</script>
        )}

        {/* Disallow cache */}
        <meta http-equiv='cache-control' content='max-age=0' />
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <meta http-equiv='expires' content='Tue, 01 Jan 1980 1:00:00 GMT' />
        <meta http-equiv='pragma' content='no-cache' />
      </Helmet>
    )
  }

  return (
    <>
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name='description' content={t('meta.description')} />
        <meta name='robots' content='noindex, nofollow' />
        <meta name='facebook-domain-verification' content='gdt37htl4djqwmarnkdyct76lx1zos' />

        <link rel='canonical' href={`https://token.trazable.io${PAGES.PROFILE}`} />

        {/* Og Meta */}
        <meta property='og:locale' content='es_ES' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={t('meta.title')} />
        <meta property='og:description' content={t('meta.description')} />
        <meta property='og:url' content={`https://token.trazable.io${PAGES.PROFILE}`} />
        <meta property='og:site_name' content='Trazable' />
        <meta property='article:publisher' content='https://www.facebook.com/trazable/' />
        <meta property='article:modified_time' content='2021-04-09T11:41:00+00:00' />
        <meta property='og:image' content={imgOG} />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        {/* End OG Meta */}

        {/* Twitter Meta */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@iotrazable' />
        <meta name='twitter:label1' content='Tiempo de lectura' />
        <meta name='twitter:data1' content='3 minutos' />
        {/* End Twitter Meta */}

        <script type='application/ld+json' className='yoast-schema-graph'>{`
          {"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://token.trazable.io/#website","url":"https://token.trazable.io/","name":"Trazable","description":"Trazabilidad end-to-end de alimentos","potentialAction":[{"@type":"SearchAction","target":"https://token.trazable.io/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"es"},{"@type":"ImageObject","@id":"https://token.trazable.io/#primaryimage","inLanguage":"es","url":"https://token.trazable.io/assets/images/icono-trazable-150.png","width":150,"height":150,"caption":"trazable logo150 farm to fork blockchain traceability"},{"@type":"WebPage","@id":"https://token.trazable.io/#webpage","url":"https://token.trazable.io/","name":"Trazable - Trazabilidad end-to-end de alimentos","isPartOf":{"@id":"https://token.trazable.io/#website"},"primaryImageOfPage":{"@id":"https://token.trazable.io/#primaryimage"},"datePublished":"2019-01-22T08:16:34+00:00","dateModified":"2021-04-09T11:41:00+00:00","description":"Optimiza tu cadena de suministro con informaci\u00f3n a tiempo real de toda la cadena, gracias a nuestra plataforma blockchain","inLanguage":"es","potentialAction":[{"@type":"ReadAction","target":["https://token.trazable.io/"]}]}]}
        `}</script>

        {/* Google Tag Manager */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && (
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T432GJH');
        `}</script>
        )}
        {/* End Google Tag Manager */}

        {/* Segment analytics */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && (
          <script>{`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=window.GATSBY_APP_SEGMENT_WRITEKEY;;analytics.SNIPPET_VERSION="4.15.3";
          setTimeout(() => analytics.load(window.GATSBY_APP_SEGMENT_WRITEKEY), 500);
          analytics.page();
          }}();
        `}</script>
        )}

        {/* Disallow cache */}
        <meta http-equiv='cache-control' content='max-age=0' />
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <meta http-equiv='expires' content='Tue, 01 Jan 1980 1:00:00 GMT' />
        <meta http-equiv='pragma' content='no-cache' />
      </Helmet>

      <div className='min-h-screen'>
        {/* Headerbar */}
        <Disclosure
          as='div'
          className='relative pb-32 overflow-hidden'
          style={{
            backgroundColor: '#090214',
            backgroundImage: `url(${imgSquaresBackground})`,
          }}
        >
          {({ open }) => (
            <>
              <nav className='relative z-10 lg:bg-transparent'>
                <div className='max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 '>
                  <div className='relative h-16 flex items-center justify-between'>
                    <div className='px-2 flex items-center lg:px-0'>
                      <div className='flex-shrink-0'>
                        <Link to={PAGES.HOME}>
                          <img className='h-5 xl:h3 w-auto' src={imgLogo} alt='Trazable' />
                        </Link>
                      </div>
                      <div className='hidden lg:block lg:ml-6 lg:space-x-4'>
                        <div className='flex'></div>
                      </div>
                    </div>
                    <div className='flex lg:hidden'>
                      {/* Mobile menu button */}
                      <Disclosure.Button className='rounded-md inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white'>
                        <span className='sr-only'>Open main menu</span>
                        {open ? (
                          <XIcon className='block flex-shrink-0 h-6 w-6' aria-hidden='true' />
                        ) : (
                          <MenuIcon className='block flex-shrink-0 h-6 w-6' aria-hidden='true' />
                        )}
                      </Disclosure.Button>
                    </div>

                    {/* Header Desktop */}
                    <div className='hidden lg:block lg:ml-4'>
                      <div className='flex items-center'>
                        {/* Profile dropdown */}
                        <Menu as='div' className='relative flex-shrink-0 ml-4'>
                          <div>
                            <Menu.Button className='rounded-full flex text-sm text-white focus:outline-none focus:bg-sky-900 focus:ring-2 focus:ring-offset-2 focus:ring-offset-sky-900 focus:ring-white'>
                              <span className='sr-only'>Open user menu</span>
                              <Avatar size={SIZE.SMALL} />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter='transition ease-out duration-100'
                            enterFrom='transform opacity-0 scale-95'
                            enterTo='transform opacity-100 scale-100'
                            leave='transition ease-in duration-75'
                            leaveFrom='transform opacity-100 scale-100'
                            leaveTo='transform opacity-0 scale-95'
                          >
                            <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg pb-1 overflow-hidden bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100'>
                              <div className='px-4 py-3 bg-gray-50'>
                                <p className='text-sm'>{auth.user?.name}</p>
                                <p className='text-sm font-medium text-gray-500 truncate'>
                                  {auth.user?.email}
                                </p>
                              </div>
                              <div className='py-1'>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to={PAGES.PROFILE}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block py-2 px-4 text-sm text-gray-700'
                                      )}
                                    >
                                      {t('header.profile')}
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={auth.signout}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'w-full text-left block py-2 px-4 text-sm text-gray-700'
                                      )}
                                    >
                                      {t('header.logout')}
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className='lg:hidden'>
                  <div className='pt-2 pb-3 px-2 space-y-1'></div>
                  <div className='pt-4 pb-3 border-t border-gray-800'>
                    <div className='flex items-center px-4'>
                      <div className='flex-shrink-0'>
                        <Avatar />
                      </div>
                      <div className='ml-3'>
                        <div className='text-base font-medium text-white'>{auth.user?.name}</div>
                        <div className='text-sm font-medium text-gray-200'>{auth.user?.email}</div>
                      </div>
                    </div>
                    <div className='mt-3 px-2'>
                      <Link
                        to={PAGES.PROFILE}
                        className='block rounded-md py-2 px-3 text-base font-medium text-gray-200 hover:text-white hover:bg-gray-800'
                      >
                        {t('header.profile')}
                      </Link>
                      <button
                        onClick={auth.signout}
                        className='block rounded-md py-2 px-3 text-base font-medium text-gray-200 hover:text-white hover:bg-gray-800'
                      >
                        {t('header.logout')}
                      </button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </nav>

              <div
                aria-hidden='true'
                className={classNames(
                  open ? 'bottom-0' : 'inset-y-0',
                  'absolute inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0 bg-contain bg-repeat bg-gray-900'
                )}
                style={{
                  backgroundColor: '#090214',
                  backgroundImage: `url(${imgSquaresBackground})`,
                }}
              >
                <div
                  className='absolute w-full h-full bg-contain bg-no-repeat bg-top'
                  style={{
                    backgroundImage: `url(${imgHeaderRectangle})`,
                  }}
                />
              </div>

              <header className='relative py-10'>
                <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
                  <h1 className='text-3xl font-bold text-white'>
                    {asideNavigation[currentTab].name}
                  </h1>
                </div>
              </header>
            </>
          )}
        </Disclosure>

        <main className='relative -mt-32'>
          <div className='max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8'>
            <div className='bg-white rounded-lg shadow overflow-hidden'>
              <div className='divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x'>
                {/* Aside */}
                {/* <aside className='py-6 lg:col-span-2'>
                  <nav className='space-y-1'>
                    {asideNavigation.map((item, index) => (
                      <button
                        key={item.name}
                        onClick={() => setCurrentTab(index)}
                        className={classNames(
                          index === currentTab
                            ? 'bg-blue-50 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700'
                            : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                          'group border-l-4 px-3 py-2 flex items-center text-sm font-medium w-full'
                        )}
                        aria-current={index === currentTab ? 'page' : undefined}
                      >
                        <item.icon
                          className={classNames(
                            index === currentTab
                              ? 'text-blue-500 group-hover:text-blue-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                          )}
                          aria-hidden='true'
                        />
                        <span className='truncate'>{item.name}</span>
                      </button>
                    ))}
                  </nav>
                </aside> */}

                {/* Content */}
                <section className='divide-y divide-gray-200 lg:col-span-12 -mr-1'>
                  {/* General section */}
                  <div className='bg-white'>
                    {/* Header */}
                    <div
                      className='relative'
                      style={{
                        backgroundImage: 'linear-gradient(rgba(22, 4, 49, 1), rgba(9, 2, 20, 1))',
                      }}
                    >
                      <div
                        className='absolute h-full w-2/3 md:w-1/3 right-0  bg-no-repeat bg-left md:bg-right bg-contain md:bg-cover'
                        style={{ backgroundImage: `url(${imgCoinBackground})` }}
                      />
                      <div className='relative flex justify-around content-center flex-col md:flex-row'>
                        {/* <div className='flex-shrink flex flex-wrap content-center px-4 m-auto mt-4 md:mt-auto'>
                            <img src={imgTokenIcon} alt='' className='h-24' />
                          </div> */}
                        <div className='relative py-10 px-4 sm:py-14 sm:px-6'>
                          <h1 className='text-2xl font-extrabold tracking-tight text-white md:text-3xl lg:text-4xl'>
                            {t('icoToken')} <code>{tokenData.data?.symbol}</code>
                          </h1>
                          <div className='mt-6 max-w-3xl text-xl text-gray-300'>
                            <dl>
                              <div className='sm:col-span-1'>
                                <dt className='text-sm font-medium text-gray-50'>
                                  {t('userTokenBuy')}
                                </dt>
                                <dd className='mt-1 text-sm text-gray-200'>
                                  <span>
                                    {formatNumber(
                                      new Big(auth.user.totalTokenAmount)
                                        .toFixed(8, Big.roundDown)
                                        .replace(/\.*0+$/, ''),
                                      i18n.language
                                    )}{' '}
                                    {tokenData.data?.symbol}
                                  </span>
                                  <span className='ml-0 sm:ml-8 block sm:inline'>
                                    {Intl.NumberFormat(i18n.language, {
                                      style: 'currency',
                                      currency: 'EUR',
                                      maximumFractionDigits: 18,
                                    }).format(
                                      Number(
                                        auth.user.transactions.reduce(
                                          (previous, current) =>
                                            previous + Number(current.totalTokenEur),
                                          0
                                        )
                                      )
                                    )}
                                  </span>
                                </dd>
                                <div className='hidden lg:block'>
                                  <Tooltip tip={t('updateWalletTooltip')}>
                                    <div className='mt-4 cursor-pointer' onClick={handleAddWallet}>
                                      <PencilAltIcon className='w-5 h-5 mx-2 float-left mt-1' />
                                      <span className='text-sm underline break-words slashed-zero leading-none'>
                                        {auth.user.publicAddress
                                          ? auth.user.publicAddress
                                          : t('updateWallet')}
                                      </span>
                                    </div>
                                  </Tooltip>
                                </div>
                                <div
                                  className='mt-4 cursor-pointer lg:hidden'
                                  onClick={handleAddWallet}
                                >
                                  <PencilAltIcon className='w-5 h-5 mx-2 float-left mt-1' />
                                  <span className='text-sm underline break-words slashed-zero leading-none'>
                                    {auth.user.publicAddress
                                      ? auth.user.publicAddress
                                      : t('updateWallet')}
                                  </span>
                                </div>
                              </div>
                            </dl>
                          </div>
                        </div>
                        <div className='flex flex-col justify-center pl-12 pb-12 md:pr-24 md:pl-0 md:pb-0'>
                          <img
                            src={salePhases}
                            className='h-44 object-contain object-left'
                            alt='timeline'
                          />
                        </div>
                      </div>
                    </div>

                    {/* Stat cards */}
                    {/* <div className='pb-12 bg-white sm:pb-16'>
                        <div className='relative'>
                          <div className='absolute inset-0 h-1/2 bg-blue-600' />
                          <div className='relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
                            <div className='max-w-4xl mx-auto'>
                              <dl className='rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3'>
                                <div className='flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r'>
                                  <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-500'>
                                    {t('stats.totalSaled')}
                                  </dt>
                                  <dd className='order-1 text-3xl font-extrabold text-blue-600'>
                                    {Intl.NumberFormat('es').format(
                                      Number(tokenData.data?.totalTokensSold)
                                    )}{' '}
                                    <code>{tokenData.data?.symbol}</code>
                                  </dd>
                                </div>
                                <div className='flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r'>
                                  <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-500'>
                                    {t('stats.currentOffer')}
                                  </dt>
                                  <dd className='order-1 text-3xl font-extrabold text-blue-600'>
                                    {tokenData.data?.sale?.name || '-'}
                                  </dd>
                                </div>
                                <div className='flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l'>
                                  <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-500'>
                                    {t('stats.collected')}
                                  </dt>
                                  <dd className='order-1 text-3xl font-extrabold text-blue-600'>
                                    {Intl.NumberFormat('es', {
                                      style: 'currency',
                                      currency: 'EUR',
                                    }).format(Number(tokenData.data?.totalProfitEur))}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div> */}
                  </div>

                  {/* Actions section */}
                  <div className='py-6 divide-y divide-gray-200'>
                    <div className='px-4 sm:px-6 space-y-4'>
                      <p>
                        <Trans
                          t={t}
                          className='italic'
                          i18nKey='actions.title.phaseThree'
                          components={{ reference: <span className='italic font-semibold' /> }}
                        />
                      </p>

                      <div className='space-y-4 sm:space-x-4 sm:space-y-0 md:flex'>
                        <ButtonMainLink
                          className='w-full md:w-64 lg:w-80'
                          onClick={() => handleClickBuyTokens('Uniswap')}
                          href={config.US}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <img src={imgUniswapLogo} alt='Uniswap' width={24} className='mr-3' />
                          <TextButton>{t('actions.buyUniswap')}</TextButton>
                        </ButtonMainLink>
                      </div>
                    </div>
                  </div>

                  {/* Transactions section */}
                  <div className='py-6 divide-y divide-gray-200'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex flex-col md:flex-row justify-between'>
                        <div>
                          <h2 className='text-lg leading-6 font-medium text-gray-900'>
                            {t('purchases.title')}
                          </h2>
                          <p className='mt-1 text-sm text-gray-500'>{t('purchases.subTitle')}</p>
                        </div>
                        {auth.user.transactions.length !== 0 && (
                          <div className='mt-2 md:mt-0 flex flex-col-reverse align-bottom'>
                            <div className='flex-shrink'>
                              <ButtonAction size={SIZE.SMALL} onClick={handleGenerateResume}>
                                {t('purchases.downloadSummary')}
                              </ButtonAction>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className='flex flex-col  mt-6'>
                        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                            <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                              <table className='min-w-full divide-y divide-gray-200'>
                                <thead className='bg-gray-50'>
                                  <tr>
                                    <th
                                      scope='col'
                                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                                    >
                                      {t('purchases.date')}
                                    </th>
                                    <th
                                      scope='col'
                                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                                    >
                                      {t('purchases.phase')}
                                    </th>
                                    <th
                                      scope='col'
                                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                                    >
                                      {t('purchases.price')}
                                    </th>
                                    <th
                                      scope='col'
                                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                                    >
                                      {t('purchases.quantity')}
                                    </th>
                                    <th
                                      scope='col'
                                      className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                                    >
                                      {t('purchases.tokens')}
                                    </th>
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>
                                  {auth.user.transactions.length !== 0 ? (
                                    auth.user.transactions.map((transaction, transactionIndex) => (
                                      <Disclosure key={transactionIndex}>
                                        {({ open }) => (
                                          <>
                                            <Disclosure.Button
                                              as='tr'
                                              key={transaction._id}
                                              className={classNames('cursor-pointer', {
                                                'bg-white': transactionIndex % 2 === 0 && !open,
                                                'bg-gray-50': transactionIndex % 2 !== 0 && !open,
                                                'bg-gray-100': open,
                                              })}
                                            >
                                              <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                                                {dayjs(transaction.createdAt).format('L HH:mm')}
                                              </td>
                                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                                {transaction.phase === 'private'
                                                  ? capitalize(t('private').toString())
                                                  : transaction.phase === 'phaseOne'
                                                  ? capitalize(t('public1').toString())
                                                  : transaction.phase === 'phaseTwo'
                                                  ? capitalize(t('public2').toString())
                                                  : transaction.phase === 'marketing'
                                                  ? capitalize(transaction.phase)
                                                  : capitalize(transaction.phase)}
                                              </td>
                                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                                {Intl.NumberFormat(i18n.language, {
                                                  style: 'currency',
                                                  currency: 'EUR',
                                                  maximumFractionDigits: 18,
                                                }).format(Number(transaction.tokenPriceEur))}
                                              </td>
                                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                                {Intl.NumberFormat(i18n.language, {
                                                  style: 'currency',
                                                  currency: 'EUR',
                                                }).format(Number(transaction.totalTokenEur))}
                                              </td>
                                              <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                                                {formatNumber(
                                                  new Big(transaction.tokenAmount)
                                                    .toFixed(8, Big.roundDown)
                                                    .replace(/\.*0+$/, ''),
                                                  i18n.language
                                                )}{' '}
                                                <code>{tokenData.data?.symbol}</code>
                                              </td>
                                              <td>
                                                <ChevronUpIcon
                                                  className={`${
                                                    open
                                                      ? 'transform rotate-180'
                                                      : 'transform rotate-90'
                                                  } w-5 h-5 text-gray-500`}
                                                />
                                              </td>
                                            </Disclosure.Button>

                                            <Disclosure.Panel
                                              as='tr'
                                              className='text-sm text-gray-500'
                                            >
                                              <td colSpan={6} className='space-y-4 px-4 pt-4 pb-2'>
                                                {transaction.vestingDetails.map(
                                                  (vestingDetail, vestingDetailIndex) => (
                                                    <div
                                                      key={vestingDetailIndex}
                                                      className='border-gray-100 border-b-2 flex justify-between flex-col sm:flex-row'
                                                    >
                                                      <div>
                                                        {formatNumber(
                                                          new Big(vestingDetail.tokens)
                                                            .toFixed(8, Big.roundDown)
                                                            .replace(/\.*0+$/, ''),
                                                          i18n.language
                                                        )}{' '}
                                                        <code>{tokenData.data?.symbol}</code>{' '}
                                                        {t('purchases.vesting', {
                                                          percentage: vestingDetail.percentage,
                                                          date: new Date(vestingDetail.startDate),
                                                        })}
                                                      </div>
                                                      <div>
                                                        <b>
                                                          <CountDownWrapper
                                                            date={new Date(vestingDetail.startDate)}
                                                          >
                                                            {({
                                                              days,
                                                              hours,
                                                              minutes,
                                                              seconds,
                                                            }) => (
                                                              <>
                                                                {`${days}D ${hours}H ${String(
                                                                  minutes
                                                                ).padStart(2, '0')}m ${String(
                                                                  seconds
                                                                ).padStart(2, '0')}s `}
                                                              </>
                                                            )}
                                                          </CountDownWrapper>
                                                        </b>
                                                        {t('purchases.remaining')}
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </td>
                                            </Disclosure.Panel>
                                          </>
                                        )}
                                      </Disclosure>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={6} className='relative'>
                                        <span className='relative m-1 block w-full rounded-lg p-12 text-center'>
                                          <TicketIcon className='mx-auto h-12 w-12 text-gray-400' />
                                          <span className='mt-2 block text-sm font-medium text-gray-900'>
                                            {t('purchases.empty')}
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* Modal Add Wallet */}
      <Modal show={modalWalletShow} toggle={handleCloseModalWallet} size={SIZE.MEDIUM}>
        <Modal.Body dismissButton toggle={handleCloseModalWallet}>
          <Modal.Title>{t('modalWallet.title')}</Modal.Title>
          {/* Disclaimer */}
          <div className='rounded-md bg-blue-50 p-4 text-left mt-4'>
            <div className='flex'>
              <div className='flex-shrink-0'>
                <InformationCircleIcon className='h-5 w-5 text-blue-400' aria-hidden='true' />
              </div>
              <div className='ml-3'>
                <h3 className='text-sm font-medium text-blue-900'>
                  {t('modalWallet.disclaimer.title')}
                </h3>
                <div className='mt-2 text-sm text-blue-800'>
                  <ul className='list-disc pl-5 space-y-1'>
                    <li>
                      <b>{t('modalWallet.disclaimer.noBittrex')}</b>
                    </li>
                    <li>{t('modalWallet.disclaimer.bep20')}</li>
                    <li>{t('modalWallet.disclaimer.customTokens')}</li>
                    <li>{t('modalWallet.disclaimer.privateKey')}</li>
                    <li>{t('modalWallet.disclaimer.metamask')}</li>
                    <li>{t('modalWallet.disclaimer.noSC')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <form className='text-left space-y-6 mt-5' onSubmit={formAccountUpdate.handleSubmit}>
            <InputField
              id='modalWallet_publicAddress'
              name='publicAddress'
              label={t('modalWallet.publicAddress')}
              value={formAccountUpdate.values.publicAddress}
              onChange={formAccountUpdate.handleChange}
              error={
                !!(
                  formAccountUpdate.touched.publicAddress && formAccountUpdate.errors.publicAddress
                )
              }
              errorText={formAccountUpdate.errors.publicAddress}
            />

            <VerificationCodeField
              id='modalWallet_verificationCode'
              name='verificationCode'
              label={t('modalWallet.verificationCode.label')}
              action={t('modalWallet.verificationCode.action')}
              actionEnable={!formAccountUpdate.values.verificationCodeGenerated}
              value={formAccountUpdate.values.verificationCode}
              onChange={formAccountUpdate.handleChange}
              onActionClick={handleGenerateVerificationCode}
              error={
                !!(
                  formAccountUpdate.touched.verificationCode &&
                  formAccountUpdate.errors.verificationCode
                )
              }
              errorText={formAccountUpdate.errors.verificationCode}
            />

            <div className='flex justify-between'>
              <ButtonAction type='submit' disabled={formAccountUpdate.isSubmitting}>
                {t('modalWallet.submit')}
              </ButtonAction>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={modalKYCShow} toggle={toggleModalKYCShow} size={SIZE.MEDIUM}>
        <Modal.Body dismissButton toggle={toggleModalKYCShow}>
          <Modal.Title>
            <p className='text-2xl'>{t('modalKYC.title')}</p>
          </Modal.Title>
          <p className='my-6'>{t('modalKYC.description')}</p>
          <img src={imgKYCIssueDate} alt='kyc issue date' />
          <div className='my-4'>
            <ButtonAction
              onClick={() => {
                toggleModalKYCShow(false)
                handleUserVerify()
              }}
            >
              {t('modalKYC.action')}
            </ButtonAction>
          </div>
        </Modal.Body>
      </Modal>

      <CookieBanner />
    </>
  )
}

export default ProfilePage
