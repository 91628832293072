import { useCountDown, Time } from '@/hooks/use-count-down'
import { memo } from 'react'

export const CountDownWrapper = memo(
  ({ date, children }: { date: Date; children: (time: Time) => JSX.Element }): JSX.Element => {
    const time = useCountDown(date)

    return children(time)
  }
)
