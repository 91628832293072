import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces'
import trazableLogoBase64 from '@/assets/images/brand/logo-black-base64'
import i18n from '@/i18n'

/**
 * Download a generated PDF document
 *
 * @param {TDocumentDefinitions} documentDefinition
 * @param {string} filename
 */
export const download = (documentDefinition: TDocumentDefinitions, filename: string): void => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs
  pdfMake.createPdf(documentDefinition).download(filename)
}

/**
 * Create a PDF document
 *
 * @param {Content} content
 * @returns {TDocumentDefinitions} document created
 */
export const create = (content: Content): TDocumentDefinitions => {
  const documentDefinition: TDocumentDefinitions = {
    info: {
      author: 'Trazable',
      creator: 'Trazable',
      producer: 'Trazable',
    },
    footer: (currentPage, pageCount) => ({
      columns: [
        '',
        {
          image: 'trazableLogo',
          width: 100,
          alignment: 'center',
        },
        {
          text: i18n.t('nsPDF:pageOf', { currentPage, totalPages: pageCount }),
          alignment: 'right',
          margin: [15, 0],
        },
      ],
      margin: [0, 20, 0, 0],
    }),
    pageMargins: [40, 60, 40, 60],
    styles: {
      header: {
        margin: [0, 0, 0, 10],
        font: 'Roboto',
        fontSize: 18,
        bold: true,
        background: '#1976d2',
      },
      subheader: {
        fontSize: 15,
        bold: true,
        decoration: 'underline',
        margin: [0, 10, 0, 5],
        font: 'Roboto',
        alignment: 'justify',
      },
      table: {
        fontSize: 10,
        margin: [0, 5, 0, 10],
      },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: 'black',
        margin: [0, 10, 0, 5],
      },
      link: {
        decoration: 'underline',
      },
      underline: {
        decoration: 'underline',
      },
    },
    images: {
      trazableLogo: trazableLogoBase64,
    },
    content,
  }

  return documentDefinition
}

/**
 * Utility PDF functions
 */
export const UTILS = {
  /**
   * Create a Document Content from Table structure
   *
   * @param {string} title
   * @param {string[]} columns
   * @param {string[][]} data
   *
   * @returns {Content} A Content parsed result
   */
  parseTableToContent: (
    title: string,
    columns: string[],
    data: string[][],
    maxLengthWord = 40
  ): Content => [
    { text: title, fontSize: 14, bold: true, margin: [0, 10, 0, 8] },
    {
      style: 'table',
      table: {
        headerRows: 1,
        widths: '*',
        heights: 40,
        body: [
          columns.map(column => ({ text: column, style: 'tableHeader' })),
          ...data.map(dataRow =>
            dataRow.map(dataCell =>
              // Split large words in to chunks of $maxLengthWord length
              dataCell
                .trim()
                .split(' ')
                .map(dataWord =>
                  dataWord.length > maxLengthWord
                    ? (dataWord.match(new RegExp(`.{1,${maxLengthWord}}`, 'g')) || []).join('- ')
                    : dataWord
                )
                .join(' ')
            )
          ),
        ],
      },
      layout: 'lightHorizontalLines',
    },
  ],

  /**
   * Get the ArrayBuffer from a pdf definition
   *
   * @param documentDefinition
   * @returns The ArrayBuffer
   */
  pdfToArrayBuffer: async (documentDefinition: TDocumentDefinitions): Promise<ArrayBuffer> => {
    return new Promise<ArrayBuffer>(resolve => {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      pdfMake.createPdf(documentDefinition).getBuffer(resolve)
    })
  },

  /**
   * Get the Data URL from a pdf definition
   *
   * @param documentDefinition
   * @returns The Data URL
   */
  pdfToDataUrl: async (documentDefinition: TDocumentDefinitions): Promise<string> => {
    return new Promise<string>(resolve => {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      pdfMake.createPdf(documentDefinition).getDataUrl(resolve)
    })
  },
}

/**
 * PDF Components
 */
export const COMPONENTS = {
  /**
   * @param space Default 1
   */
  spacer: (space = 1): Content => ({
    text: '',
    margin: [0, space * 10],
  }),

  header: (text: string): Content => ({
    table: {
      widths: '*',
      body: [
        [
          {
            text,
            fillColor: '#1976d2',
            color: '#fff',
            styles: 'header',
            margin: [15, 0],
            bold: true,
          },
        ],
      ],
    },
    margin: [0, 0, 0, 15],
    layout: 'noBorders',
  }),

  subHeader: (text: string): Content => ({
    text,
    style: 'subheader',
  }),
}
