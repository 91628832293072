import { InputHTMLAttributes, MutableRefObject, ReactNode } from 'react'
import classNames from 'classnames'

export interface VerificationCodeFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Mutable ref object to access child dom
   */
  innerRef?: MutableRefObject<HTMLInputElement | null>
  /**
   * Input id
   */
  id: string
  /**
   * Input name
   */
  name: string
  /**
   * Input label
   */
  label?: ReactNode
  /**
   * Input description
   */
  description?: ReactNode

  /**
   * Input action
   */
  action: ReactNode

  actionEnable: boolean

  // HANDLERS
  onActionClick: () => void

  // ERROR
  /**
   * Content to render inside the button
   */
  error?: boolean
  /**
   * Error message
   */
  errorText?: ReactNode
  /**
   * Dark mode styles
   */
  dark?: boolean
}

/**
 * Button UI component for user interaction
 */
export const VerificationCodeField = ({
  innerRef,
  id,
  name,
  type = 'text',
  label,
  description,
  action,
  actionEnable = true,
  className,
  onActionClick,
  error = false,
  errorText,
  dark,
  ...props
}: VerificationCodeFieldProps): JSX.Element => {
  return (
    <div>
      <label
        htmlFor={id}
        className={classNames('block text-sm font-medium', dark ? 'text-white' : 'text-gray-700')}
      >
        {label}
      </label>
      <div className='mt-1 flex rounded-md shadow-sm w-full'>
        <div className='relative flex items-stretch flex-grow focus-within:z-10'>
          <input
            ref={innerRef}
            id={id}
            name={name}
            type={type}
            className={classNames('block w-full sm:text-sm rounded-md', className, {
              'rounded-r-none': type === 'search',
              'focus:ring-blue-500 focus:border-blue-500 border-gray-300 ': !error,
              'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500':
                error,
            })}
            {...props}
          />

          {actionEnable && (
            <div className='absolute inset-y-0 right-0 pr-3 flex items-center'>
              <button
                className={classNames('sm:text-sm text-gray-900 underline cursor-pointer')}
                type='button'
                onClick={onActionClick}
              >
                {action}
              </button>
            </div>
          )}
        </div>
      </div>

      {!errorText && description && (
        <p className={classNames('mt-2 text-sm', dark ? 'text-gray-100' : 'text-gray-500')}>
          {description}
        </p>
      )}
      {error && errorText && <p className='mt-2 text-sm text-red-600'>{errorText}</p>}
    </div>
  )
}
